<template>
  <div class="Analyze">
    <div class="Analyzenav">
      <div class="Daterange">
        {{ $t("label.chatter.dateRange") }}：
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span>{{ Daterange }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(range, num) in Dateranges"
              :key="num"
              :command="range"
              >{{ range }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="datepicker"
        v-show="Daterange == `${$t('label.custom_time')}`"
      >
        <el-date-picker
          size="small"
          v-model="datepickervalue"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          :picker-options="pickerOptions"
          start-placeholder="YYYY-MM-DD"
            end-placeholder="YYYY-MM-DD"
        >
        </el-date-picker>
      </div>
    </div>
    <Analyzedate :analyzedate="analyzedate"></Analyzedate>
    <Analyechart
      :h1="title"
      :echarttitle="echarttitle"
      :echartstartdate="echartstartdate"
      :echartenddate="echartenddate"
      :timeHorizon="timezon"
    ></Analyechart>
    <div class="table">
      <performList
        :startdate="echartstartdate"
        :enddate="echartenddate"
        :title="$t('label.all_forms')"
        :dataList="tableform"
        :tableAttr="tableformtitle"
      ></performList>
      <!-- 分页 -->
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[25, 50, 100]"
              layout=" sizes,prev, next,pager"
              :page-size="pagesize"
              :total="currentPagetotle"
            >
            </el-pagination>
          </div>
    </div>
    
  </div>
</template>
<script>
import Analyzedate from './components/Analyzedate'
import Analyechart from "./components/Analyechart"
import performList from '@/views/marketForm/detailForm/component/performList.vue'
import * as marketChannel from "./api.js";//api
import {getPreMonth} from '@/config/reportDataType/unitData.js'

export default {
  components: {
    Analyzedate,
    Analyechart,
    performList,
  },
  data() {
    return {
      Daterange: this.$i18n.t("label.notification.last30days"),
      startdate: "", //开始时间
      enddate: "", //结束时间
      Dateranges: [
        this.$i18n.t("component.timeline.label.today"),
        this.$i18n.t("label.brief.thisweek"),
        this.$i18n.t("this.year"),
        this.$i18n.t("label.notification.last30days"),
        this.$i18n.t("label.custom_time"),
      ],
      datepickervalue: [getPreMonth(),new Date().toLocaleDateString().replace(/\//g, "-")],
      pickerOptions:{
        disabledDate(v){
                return v.getTime() > new Date().getTime();
              }
      },
      analyzedate: {
        title: this.$i18n.t("label.aggregated_formsdata"),
        startdate: "",
        enddate: "",
        metrics: [],
        name: "",
      },
      echarttitle: this.$i18n.t("label.session_date"),
      echartstartdate: "",
      echartenddate: "",
      title: this.$i18n.t('label.total_views'),
      tableform: [],//all form 表格内容
      tableformtitle: [],//all form 表格表头
      timezon: '',//echarts折线图的时间间隔
      currentPage: 1,
      pagesize: 25,
      currentPagetotle: 0,
    }
  },
  watch: {
    datepickervalue() {
      this.startdate = this.datepickervalue[0];
      this.enddate = this.datepickervalue[1];
      this.analyzedate.startdate = this.datepickervalue[0];
      this.analyzedate.enddate = this.datepickervalue[1];
      this.echartstartdate = this.datepickervalue[0];
      this.echartenddate = this.datepickervalue[1];
      let yearone = this.datepickervalue[0].split("-")[0];
      let monthone = this.datepickervalue[0].split("-")[1];
      let yeartwo = this.datepickervalue[1].split("-")[0];
      let monthtwo = this.datepickervalue[1].split("-")[1];
      if (yearone == yeartwo && monthone == monthtwo) {
        this.timezon = "everyday";
      } else {
        this.timezon = "everymonth";
      }
      this.getAnalyzedate();
      this.getdatalist();
    },
    // Daterange() {
    //   this.$store.state.creatform.totaldaterange = this.Daterange
    // }
  },
  methods: {
    handleCommand(command) {
      this.Daterange = command;
      this.getformdates(command);
      this.currentPage = 1;
      if (command !== this.$i18n.t("label.custom_time")) {
        this.getAnalyzedate();
        this.getdatalist();
      } else {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var nowDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
        this.datepickervalue = [getPreMonth(),new Date().toLocaleDateString().replace(/\//g, "-")]
        this.analyzedate.startdate = nowDate;
        this.analyzedate.enddate = nowDate;
        this.echartstartdate = nowDate;
        this.echartenddate = nowDate;
      }
    },
    //获取时间范围
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      if (time == this.$i18n.t("component.timeline.label.today")) {
        this.startdate = nowDate;
        this.enddate = nowDate;
        this.timezon = "everyday";
      }
      let lastDate = "";
      if (time == this.$i18n.t("label.brief.thisweek")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 7);
        this.timezon = "everyday";
      }
      if (time == this.$i18n.t("label.notification.last30days")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
        this.timezon = "everyday";
      }
      if (time == this.$i18n.t("this.year")) {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
        this.timezon = "everymonth";
      }
      // if (time == 'All time') {
      //   this.startdate = '';
      //   this.enddate = '';
      //   this.timezon = 'everymonth';
      // }
      if (lastDate) {
        var lastY = lastDate.getFullYear();
        var lastM = lastDate.getMonth() + 1;
        var lastD = lastDate.getDate();
        var lastdate =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
      this.analyzedate.startdate = this.startdate;
      this.analyzedate.enddate = this.enddate;
      this.echartstartdate = this.startdate;
      this.echartenddate = this.enddate;
    },
    //获取基本数据
    getAnalyzedate() {
      let obj = {
        id: "",
        endTime: this.enddate,
        startTime: this.startdate,
      };
      marketChannel.getFormInfo(obj).then((res) => {
        this.analyzedate.startdate = this.startdate;
        this.analyzedate.enddate = this.enddate;
        if (res.data.submissionRate == null || res.data.submissionRate == 0) {
          res.data.submissionRate = "0.00%";
        }

        this.analyzedate.name = "";
        this.analyzedate.metrics = [
          {
            dt: this.$i18n.t("label.submissions"),
            dd: res.data.submissions,
            key: "submissions",
          },
          {
            dt: this.$i18n.t("label.submission_rate"),
            dd: res.data.submissionRate,
            key: "submissionRate",
          },
          {
            dt: this.$i18n.t("label.views"),
            dd: res.data.views,
            key: "views",
          },
        ];
      });
    },
    //获取form列表数据
    getdatalist() {
      let obj = {
        endTime: this.enddate,
        startTime: this.startdate,
        id: '',
        pageNum: this.currentPage,
        pageSize: this.pagesize,
      };

      marketChannel.getFormList(obj).then((res) => {
        this.currentPagetotle = res.data.totalCount;
        this.tableformtitle = [
          {
            schemefieldName: this.$i18n.t("label.form_content"),
            schemefieldType: "L",
            key: "subject",
          },
          {
            schemefieldName: this.$i18n.t("label.views"),
            schemefieldType: "L",
            key: "views",
          },
          {
            schemefieldName: this.$i18n.t("label.submissions"),
            schemefieldType: "PE",
            key: "submissions",
          },
          {
            schemefieldName: this.$i18n.t("label.submission_rate"),
            schemefieldType: "L",
            key: "submissionRate",
          },
        ];
        this.tableform = res.data.formlist;
      })
    },
    //分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.getdatalist();

    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getdatalist();
    },
  },
  created() {
    this.getformdates(this.Daterange);
    this.getAnalyzedate();
    this.getdatalist();
    // this.$i18n.t("label.notification.last30days")
// 
//     if (this.$store.state.creatform.totaldaterange) {
//       this.Daterange = this.$store.state.creatform.totaldaterange
//     }
  },
};
</script>
<style lang='scss'  scoped>
.Analyze {
  background: #f0f0f0;
  width: 100%;
  height: 80vh;
  overflow: auto;
  margin-bottom: 20px;
}
.Analyzenav {
  height: 55px;
  width: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  .Daterange {
    float: left;
    margin-left: 16px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    width: 250px;
    line-height: 55px;
    .el-dropdown-link {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #2D6CFC;
      letter-spacing: 0;
    }
  }
  .datepicker {
    margin-left: 15px;
    ::v-deep {
      .el-range-editor.el-input__inner {
        padding: 0;
        cursor: pointer;
        width: 220px;
      }
      .el-input__inner {
        border: 0;
        height: 20px;
        line-height: 20px;
      }
      .el-range-input {
        cursor: pointer;
        color: #2D6CFC;
      }
      .el-date-editor .el-range__icon {
        line-height: 20px;
        margin: 0;
        width: 20px;
      }
      .el-date-editor .el-range-separator {
        line-height: 20px;
        padding: 0;
      }
      .el-date-editor .el-range-input {
        width: 90px;
      }
      .el-date-editor .el-range__close-icon {
        line-height: 22px;
        width: 20px;
      }
    }
  }
}
.table {
  width: 100%;
  margin-top: 10px;
  background: #ffff;
  .block{
    height: 90px;
    padding: 10px 0;
    .el-pagination{
      float:right;
    }
  }
}
</style>