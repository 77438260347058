<template>
  <div class="marketform">
    <div class="Form">
      <!-- 头部 -->
      <div class="formhead">
        <div class="formheadleft">
          <p class="headleftfirstp">
            {{ $t("label.form") }}
            <span class="headleftfirstspan"
              >({{ currentPagetotle }} {{ $t("label.form") }})</span
            >
            <!-- <br />
            <i class="el-icon-warning"></i
            ><span class="headleftsecondspan">Non-Lighting Forms</span> -->
          </p>
        </div>
        <div class="formheadright">
          <el-row style="margin: 15px 15px 0 0;">
            <!-- <el-button type="primary" plain @click="dialogFolderVisible = true" size="mini"
              >Create folder</el-button
            > -->
            <el-button
              type="primary"
              plain
              @click="goCreate"
              class="autofocus"
              size="mini"
            >
              {{ $t("label.create_form") }}</el-button
            >
          </el-row>
        </div>
      </div>
      <!-- Manage Analyze切换 -->
      <div class="formnav">
        <div>
          <p
            :class="activeIndex == `${$t('label.manage')}` ? 'pselect' : ''"
            @click="selectp($t('label.manage'))"
          >
            {{ $t("label.manage") }}
          </p>
          <p
            :class="
              activeIndex == `${$t('vue_label_systemSettings_analyse')}`
                ? 'pselect'
                : ''
            "
            @click="selectp($t('vue_label_systemSettings_analyse'))"
          >
            {{ $t("vue_label_systemSettings_analyse") }}
          </p>
        </div>
        <span>
          <!-- <i class="el-icon-s-data"></i> View detailed forms analytics -->
        </span>
      </div>
      <div class="marketbody" v-show="activeIndex == `${$t('label.manage')}`">
        <!-- <div v-show="textshow" class="texttf">
          <p>
            <span @click="texthidden"><b class="el-icon-arrow-left"></b></span>
            <i class="el-icon-folder"></i>{{ textvalue }}
          </p>
        </div> -->
        <!-- 搜索 筛选 导出 -->
        <div class="bodynav">
          <div class="bodynavleft">
            <el-input
              class="search-text"
              v-model="searchValue"
              :placeholder="$t('label.search_forms')"
              @keyup.enter.native="searchList"
              prefix-icon="el-icon-search"
            ></el-input>
            <div class="Daterange">
              {{ $t("label.chatter.dateRange") }}：
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <span>{{ Daterange }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(range, num) in Dateranges"
                    :key="num"
                    :command="range"
                    >{{ range }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              class="datepicker"
              v-show="Daterange == `${$t('label.custom_time')}`"
            >
              <el-date-picker
                v-model="datepickervalue"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                :picker-options="pickerOptions"
                start-placeholder="YYYY-MM-DD"
            end-placeholder="YYYY-MM-DD"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="bodynavright">
            <!-- 筛选器 -->
            <!-- <el-button size="mini" type="primary" plain @click="handleClicks()">
              {{ $t("label.weixin.report.filters") }}
            </el-button> -->
            <!-- 表格批量删除 -->
            <!-- <el-button
            type="primary"
            plain
            @click="deleteformevent"
            v-show="deleteform"
            size="mini"
            >Delete</el-button> -->
            <!-- 导出 -->
            <el-button type="primary" plain @click="exportdate" size="mini">{{
              $t("label.export_data")
            }}</el-button>
          </div>
        </div>
        <div class="table">
          <!-- 表格 -->
          <template>
            <el-table
              :data="tableData"
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
              border
              :cell-style="{ padding: '0' }"
              :header-cell-style="{ background: '#FAFAF9', height: '40px' }"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
              @selection-change="handleSelectionChange"
              :height="tableheight"
              :class="deleteform ? 'batchtable' : 'formtable'"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <template v-for="(tit, titindex) in tableAttr">
                <slot :item="titindex">
                  <el-table-column
                    v-if="tit.key == 'name'"
                    width="380"
                    :show-overflow-tooltip="false"
                  >
                    <template slot="header">
                      <div class="tableHeadField" v-show="!deleteform">
                        <!-- <el-popover
                          placement="top-start"
                          trigger="hover"
                          popper-class="my-tooltip table-head-tooltip"
                          :content="tit.column"
                        > -->
                        <span>
                          {{ tit.column }}
                        </span>
                        <!-- </el-popover> -->
                        <svg
                          class="icon"
                          aria-hidden="true"
                          :style="{
                            height: '12px',
                            'vertical-align': '',
                            transform: tit.sort ? 'rotate(180deg)' : '',
                          }"
                          v-show="tit.lock"
                        >
                          <use href="#icon-icon_sort_top"></use>
                        </svg>

                        <!-- <el-popover
                          placement="bottom"
                          width="100"
                          trigger="hover"
                          :visible-arrow="false"
                          class="popover-span"
                          :ref="`${tit.column}-popover`"
                          v-if="showLock"
                        >
                          <el-checkbox>{{
                            $t("vue_label_commonobjects_detail_locking")
                          }}</el-checkbox> -->
                        <span
                          slot="reference"
                          @click="titimg(tit)"
                          class="popover-span"
                        >
                          <svg
                            class="icon"
                            aria-hidden="true"
                            style="width: 11px"
                          >
                            <use href="#icon-xiajiantou"></use>
                          </svg>
                        </span>
                        <!-- </el-popover> -->
                      </div>
                      <div class="batchremove" v-show="deleteform">
                        <!-- 已选定{selectnumber}个表单 -->
                        {{$t("c571", {selectnumber: selectnumber,})}}
                        <svg class="icon" aria-hidden="true" style="cursor:pointer;">
                          <use href="#icon-delete"></use>
                        </svg>
                        <span @click="deleteformevent">{{$t('pagecreator_page_button_delete')}}</span>
                      </div>
                    </template>
                    <template slot-scope="scope" v-if="tit.key == 'name'">
                      <div class="tablename">
                        <div class="tablenamefirst">
                          <span class="tablenamebloder">
                            <!-- <i
                              :class="
                                scope.row.text == 'text' ? 'el-icon-folder' : ''
                              "
                            ></i> -->
                            <el-popover
                              placement="top-start"
                              popper-class="my-tooltip table-head-tooltip"
                              trigger="hover"
                              :content="scope.row.subject"
                            >
                              <span
                                slot="reference"
                                :id="
                                  scope.row.vshow ? 'spanlength' : 'spanlong'
                                "
                                @click="subdetail(scope.row)"
                              >
                                <!-- @click="tabletext(scope.row.name, scope.row.text)" -->
                                {{ scope.row.subject }}</span
                              >
                            </el-popover> </span
                          ><br />
                          <!-- <span
                            v-show="scope.row.text !== 'text'"
                            class="tablesecol"
                            ><span class="secol"></span> {{ scope.row.detail }}
                            {{ scope.row.deteil }}</span> -->
                        </div>
                        <div v-if="scope.row.vshow" class="tablenamesecond">
                          <button
                            @click="editclick(scope.row.id, scope.row.subject,scope.row)"
                          >
                            {{ $t("label.edit") }}
                          </button>
                          <button @click="cloneformevent(scope.row)">
                            {{ $t("label.clone") }}
                          </button>
                          <button
                            class="actionselect"
                            @click="action(scope.row)"
                          >
                            {{ $t("label.quickbooks.button.actions") }}
                            <i class="el-icon-caret-bottom"></i>
                            <ul
                              :class="
                                scope.$index + 1 == pagesize ||
                                scope.$index + 1 == pagesize - 1
                                  ? 'actiontop'
                                  : 'actionoption'
                              "
                              v-show="scope.row.optionshow"
                            >
                              <li
                                v-for="item in scope.row.selectvalue
                                  .selectvalue1"
                                :key="item"
                                @click="rowoptionshow(scope.row, item)"
                              >
                                {{ item }}
                                <svg
                                  class="icon"
                                  aria-hidden="true"
                                  v-show="
                                    item == `${$t('label.create_contacts')}`
                                  "
                                >
                                  <use href="#icon-shareblue"></use>
                                </svg>
                              </li>
                              <div class="borderli"></div>
                              <li
                                v-for="itmt in scope.row.selectvalue
                                  .selectvalue2"
                                :key="itmt"
                                @click="rowoptionshow(scope.row, itmt)"
                              >
                                {{ itmt }}
                              </li>
                            </ul>
                          </button>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="tit.key !== 'name'"
                    :show-overflow-tooltip="true"
                  >
                    <template slot="header">
                      <div class="tableHeadField" v-show="!deleteform">
                        <!-- <el-popover
                          placement="top-start"
                          trigger="hover"
                          popper-class="my-tooltip table-head-tooltip"
                          :content="tit.column"
                        > -->
                        <span>
                          {{ tit.column }}
                        </span>
                        <!-- </el-popover> -->
                        <svg
                          class="icon"
                          aria-hidden="true"
                          :style="{
                            height: '12px',
                            'vertical-align': '',
                            transform: tit.sort ? 'rotate(180deg)' : '',
                          }"
                          v-show="tit.lock"
                        >
                          <use href="#icon-icon_sort_top"></use>
                        </svg>

                        <!-- <el-popover
                          placement="bottom"
                          width="100"
                          trigger="hover"
                          :visible-arrow="false"
                          class="popover-span"
                          :ref="`${tit.column}-popover`"
                          v-if="showLock"
                        >
                        <el-checkbox>{{
                          $t("vue_label_commonobjects_detail_locking")
                        }}</el-checkbox> -->
                        <span
                          slot="reference"
                          @click="titimg(tit)"
                          class="popover-span"
                        >
                          <svg
                            class="icon"
                            aria-hidden="true"
                            style="width: 11px"
                          >
                            <use href="#icon-xiajiantou"></use>
                          </svg>
                        </span>
                        <!-- </el-popover> -->
                      </div>
                      <div class="batchremove" v-show="deleteform"></div>
                    </template>
                    <template slot-scope="scope">
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <!--  <el-popover
                          placement="top-start"
                          :open-delay="500"
                          trigger="hover"
                          popper-class="my-tooltip table-head-tooltip"
                          :content="scope.row[tit.key]"
                        >
                          <span slot="reference"> -->
                        {{ scope.row[tit.key] }}
                        <!-- </span>
                        </el-popover> -->
                      </div>
                    </template>
                  </el-table-column>
                </slot>
              </template>
            </el-table>
          </template>
          <!-- 分页 -->
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[25, 50, 100]"
              layout=" sizes,prev, next,pager"
              :page-size="pagesize"
              :total="currentPagetotle"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <Analyze
        v-if="activeIndex == `${$t('vue_label_systemSettings_analyse')}`"
      ></Analyze>
    </div>
    <!-- 创建folder -->
    <el-dialog
      title="Create Folder"
      :visible.sync="dialogFolderVisible"
      width="587px"
      custom-class="dialog"
    >
      <div class="dialogdiv">
        <p>Folder Name</p>
        <input type="text" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFolderVisible = false">cancel</el-button>
        <el-button type="primary" @click="dialogFolderVisible = false"
          >Add Folder</el-button
        >
      </div>
    </el-dialog>
    <!-- 克隆form -->
    <el-dialog
      :title="$t('label.clone_form')"
      :visible.sync="Cloneform"
      width="587px"
      custom-class="dialog"
    >
      <div class="dialogdiv">
        <div>
          <p>
            {{ $t("label.name_copy") }}
          </p>
        </div>
        <p>{{ $t("label.new_formname") }}</p>
        <input type="text" v-model="Cloneformvalue" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Cloneform = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="formclone">{{
          $t("label.clone")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 导出form -->
    <el-dialog
      :title="exportitle"
      :visible.sync="ExportData"
      width="587px"
      custom-class="dialog"
    >
      <div class="dialogdiv">
        <p>{{ $t("label.file_format") }}</p>
        <el-select v-model="ExportDatavalue">
          <el-option
            v-for="item in ExportDataoptions"
            :key="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <!-- <p>{{ $t("label.exported_to") }}</p>
        <p>qimh@cloudcc.com {{ $t("label.posted_your") }}</p>
        <p>{{ $t("label.notifications_center") }}</p>
        <div>
          {{ $t("label.qetting_notifications") }}
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ExportData = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="exporedata">{{
          $t("label.reportexport")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- appears on -->
    <el-dialog
      title="Form dependencies"
      :visible.sync="appearson"
      width="587px"
      custom-class="dialog"
    >
      <h1>The form‘Leed Form’is used in {{ appearsonselect }}.</h1>
      <div class="dialogappearson">
        <h5>CloudCC PAGES</h5>
        <h6>
          <p>Landing Page CloudCC</p>
          <span>Landing Page CloudCC</span>
        </h6>
        <h6>
          <p>Landing Page - HS Example</p>
          <span>Landing Page CloudCC</span>
        </h6>
      </div>
    </el-dialog>
    <!-- share -->
    <!-- <el-dialog
      :title="$t('label.share_form')"
      :visible.sync="sharetype"
      width="587px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <div class="sharecontent">
        <el-tabs v-model="shareName" @tab-click="handleClicked">
          <el-tab-pane
            :label="$t('label.embed_code')"
            name="first"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.share_link')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane :label="$t('label.qrcode')" name="third"></el-tab-pane>
        </el-tabs>
        <div class="embcode" v-show="Ecodeshow">
          <p>
            {{ $t("label.embed_website") }}
          </p>
          <div class="sourcecode">
            <div class="embcontent">
              <p>{{ vhtml }}</p>
            </div>
          </div>
        </div>
        <div class="Slink" v-show="Slinkshow">
          <p>
            {{ $t("label.start_below") }}
          </p>
          <div class="slinkcontent">
            <input type="text" v-model="shareurl" />
            <button @click="copyText(shareurl)">
              {{ $t("pagecreator.page.button.copy") }}
            </button>
          </div>
          <span
            >{{ $t("label.preciew_tab") }}
            <svg class="icon" aria-hidden="true">
              <use href="#icon-share"></use>
            </svg>
          </span>
        </div>
        <div class="QRcode" v-show="QRcodeshow">
          <img :src="qrcodeUrl" alt="" />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sharetype = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="copyText(vhtml)" v-show="Ecodeshow">
          {{ $t("pagecreator.page.button.copy") }}</el-button
        >
      </div>
    </el-dialog> -->
    <!-- 分享 -->
    <share ref="share" :checkedid="checkedid"></share>
    <!-- delete弹框 -->
    <el-dialog
      :title="$t('label.emailobject.delete') + deletetitle + ' ?'"
      :visible.sync="deleteshow"
      width="587px"
      top="20vh"
    >
      <div class="deletecontent">
        <p>
          {{ $t("label.delete_about") }} '{{ deletetitle }}'.
          {{ $t("label.undone_external page") }}
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteshow = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="deletefrom">{{
          $t("pagecreator.page.button.delete")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 筛选器 -->
    <Filter-panel
      class="screen"
      v-if="showScreen"
      :viewInfos="viewInfo"
      :view-id="viewId"
      :objId="objId"
      :prefix="prefix"
      @viewIds="viewIds"
      @filterBoard="filterBoard"
      :style="'20px'"
    ></Filter-panel>
  </div>
</template>
<script >
import {copyText} from "@/utils/copyInfo"
import {
  FilterPanel,
} from '@/components/index'
import Analyze from "./Analyze.vue"
import * as marketChannel from "./api.js";//api
import axios from "@/config/httpConfig";
import {getPreMonth} from '@/config/reportDataType/unitData.js'
import share from "@/views/marketForm/components/share.vue";

export default {

  components: {
    FilterPanel,
    Analyze,
    share
  },
  data() {
    return {
      //筛选器
      showButtons: ["liebiaoshitu", "fenpingshitu", "shezhi", "baobiao", "daochu", "dayin", "biaoqian"],
      isLabelView: false,
      isChart: false,
      newViewId: '',
      showScreen: false,
      prefix: "004",
      objId: "lead",
      viewId: '0',
      viewInfo: { "isUsedForMobile": false, "ismodify": "false", "id": "0", "label": "我最近查看的", "isdefault": null, "isCurrent": true },
      Daterange: this.$i18n.t("label.notification.last30days"),
      Dateranges: [
        this.$i18n.t('component.timeline.label.today'),
        this.$i18n.t("label.brief.thisweek"),
        this.$i18n.t("this.year"),
        this.$i18n.t("label.notification.last30days"),
        this.$i18n.t("label.all_time"),
        this.$i18n.t("label.custom_time")
      ],
      type: this.$i18n.t("label.all_forms"),
      tableAttr: [
        { column: this.$i18n.t("label.name"), key: "name", lock: false, sort: true },
        { column: this.$i18n.t("label.views"), key: "views", lock: false, sort: true },
        { column: this.$i18n.t("label.submission_rate"), key: "submission_rate", lock: false, sort: true },
        { column: this.$i18n.t("label.submissions"), key: "submissions", lock: false, sort: true },
        { column: this.$i18n.t("label.knowledgebase.last.modified"), key: "lastmodifydate", lock: false, sort: true }
      ],
      tableData: [],
      ExportDatavalue: "xls",
      ExportDataoptions: ["xls", "xlsx", 'csv'],
      // --------------------------
      selectvalue: 'Actions',
      activeIndex: this.$i18n.t("label.manage"),
      searchValue: null,
      selecttf: true,
      //表格
      showLock: true,
      ismodifyFalg: "false",
      noDataTip: false,
      tableheight: `${document.body.offsetHeight - 310}px`,
      textshow: false,
      textvalue: "",
      currentPage: 1,
      pagesize: 25,
      currentPagetotle: 0,
      dialogFolderVisible: false,
      rowindex: 0,
      Cloneform: false,
      Cloneformvalue: "",
      Cloneformid: '',//克隆id
      ExportData: false,
      appearson: false,
      appearsonselect: "",
      datepickervalue: [getPreMonth(),new Date().toLocaleDateString().replace(/\//g, "-")],
      pickerOptions:{
        disabledDate(v){
                return v.getTime() > new Date().getTime();
              }
      },
      ultipleSelection: null,
      deleteform: false,
      exportitle: "",
      shareName: "first",//share弹框中的切换
      Ecodeshow: true,//share弹框 Embed code开关
      Slinkshow: false,//share弹框Share link开关
      QRcodeshow: false,//share弹框QR code开关
      vhtml: '',
      shareurl: '',
      //share弹框Embed code 的源码内容

      qrcodeUrl: '',//分享的二维码

      deleteshow: false,//delete弹框
      deletetitle: "",//delete弹框标题

      selectnumber: null,//表格选中个数

      multipleSelection: [],
      checkedid: null,//选中form 的 id
      startdate: '',//开始时间
      enddate: '',//结束时间
      sortField: 'lastmodifydate',//排序字段
      sortDir: 'desc',//排序方式  asc是从小到大 desc 从大到小
    }
  },
  watch: {
    // searchValue: {
    //   handler(val) {
    //     this.getdata();
    //   },
    // },
    datepickervalue() {
      this.startdate = this.datepickervalue[0];
      this.enddate = this.datepickervalue[1];
      this.getdata();
    },
    // Daterange() {
    //   this.$store.state.creatform.Daterange = this.Daterange
    // }
  },
  methods: {
    copyText,
    // handleClick(action, flag) {
    //   this.showScreen = true
    // },
    handleClicks() { this.showScreen = true },
    filterBoard() {
      this.showScreen = !this.showScreen
      this.isLabelView = false
      this.isChart = false
    },
    viewIds(val) {
      this.newViewId = val
      // this.getNewViewList()
    },
    //排序
    titimg(tit) {
      tit.lock = true;
      tit.sort = !tit.sort;
      this.sortField = tit.column;//排序字段
      if (tit.key == 'name') {
        this.sortField = "Name"
      } else if (tit.key == 'views') {
        this.sortField = "Views"
      } else if (tit.key == 'submissions') {
        this.sortField = "Submissions"
      } else {
        this.sortField = tit.key
      }
      for (let i = 0; i < this.tableAttr.length; i++) {
        if (this.tableAttr[i].key !== tit.key) {
          this.tableAttr[i].lock = false;
        }
      }
      if (tit.sort == true) {
        this.sortDir = 'desc'
      } else {
        this.sortDir = 'asc'
      }
      this.getdata();
      //sort为false  向上
      //sort为true   向下
    },
    handleClicked(tab) {
      if (tab.index === "0") {
        this.Ecodeshow = true
        this.Slinkshow = false
        this.QRcodeshow = false
      } else if (tab.index === "1") {
        this.Ecodeshow = false
        this.Slinkshow = true
        this.QRcodeshow = false
      } else {
        this.Ecodeshow = false
        this.Slinkshow = false
        this.QRcodeshow = true
      }
    },
    editclick(id, name,row) {
      this.$store.state.creatform.actionType = 'edit'
      this.$store.state.creatform.itemDataId = id;
      this.$store.state.creatform.objectid = row.objectid;
      this.$store.state.creatform.ownerobjectid = row.ownerobjectid;
      this.$router.push({
        name: 'createFrom',
        params: {
          formname: name,
          tap: { index: '0' },
        }
      })
    },
    exportdate() {
      this.ExportData = true;
      this.exportitle = this.$i18n.t("label.export_data");
    },
    subdetail(even) {
      this.$store.state.creatform.itemDataId = even.id;
      this.$router.push({
        name: 'Marketdetail',
        params: {
          id: '0',
        }
      })
    },
    rowoptionshow(even, item) {
      even.optionshow = false;
      this.checkedid = even.id;
      this.$store.state.creatform.itemDataId = even.id;
      if (item == this.$i18n.t("label.report.details")) {
        
        this.$router.push({
          name: 'Marketdetail',
          params: {
            id: '0',
          }
        })
      }
      if (item == this.$i18n.t("label.emailtocloudcc.button.view")) {
        let obj = {
          id: this.checkedid,
        }
        marketChannel.getShareInfo(obj).then((res) => {
          this.vhtml = res.data.pagecontent;
          this.shareurl = res.data.url;
          this.qrcodeUrl = res.data.qrcodeUrl;
          if (item == this.$i18n.t("label.emailtocloudcc.button.view")) {
            window.open(res.data.url, '_blank');
          }
        })
      }
      if (item == this.$i18n.t("label.view_submissions")) {
        this.$router.push({
          name: 'Marketdetail',
          params: {
            id: '3',
          }
        })
      }
      if (item == this.$i18n.t("label.export_submission")) {
        this.ExportData = true;
        this.exportitle = this.$i18n.t("label.export_submission")
      }
      if (item == this.$i18n.t("label.file.share")) {
        this.getshare(even)
      }
      if (item == this.$i18n.t("pagecreator.page.button.delete")) {
        // let newdate = new Date();
        // let Y = newdate.getFullYear();//年
        // var M = newdate.getMonth();//月
        // let D = newdate.getDate();//日
        // let H = newdate.getHours();//时
        // let m = newdate.getMinutes(); //分
        // let S = newdate.getSeconds();//秒
        // let monlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        // let Mon = monlist[M];
        // let Mdate;
        // if (H >= 12) {
        //   Mdate = Mon + '  ' + D + " , " + Y + "  " + (H - 12) + ":" + m + ":" + S + '  PM '
        // } else {
        //   Mdate = Mon + '  ' + D + " , " + Y + "  " + H + ":" + m + ":" + S + ' AM '
        // }
        // this.deletetitle = even.subject + "(" + Mdate + ")"
        this.deletetitle = even.subject
        this.deleteshow = true;
      }
      if (item == this.$i18n.t("label.create_contacts")) {
        this.$router.push({
          name: 'particulars',
          params: {
            id: even.name,
            title: 'Active list',
            acsta: true,
          }
        })
      }
    },
    action(key) {
      key.optionshow = true;
    },
    goCreate() {
      this.$router.push({
        path: '/marketForm/selectTemplate'
      })
    },
    selectp(key) {
      this.activeIndex = key;
      this.$store.state.creatform.itemDataId = '';
      this.Daterange = this.$i18n.t("label.notification.last30days")
    },
    //搜索
    searchList() {
      this.getdata();
    },
    //时间筛选
    handleCommand(command) {
      this.Daterange = command;
      this.getformdates(command);
      this.currentPage = 1;
      if (command !== this.$i18n.t("label.custom_time")) {
        this.getdata();
      } else {
        this.datepickervalue = [getPreMonth(),new Date().toLocaleDateString().replace(/\//g, "-")]
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectnumber = val.length;
      if (val.length > 1) {
        this.deleteform = true
      } else {
        this.deleteform = false
      }
    },
    //批量删除
    deleteformevent() {
        let sumId = []
        for (var i = 0; i < this.multipleSelection.length; i++) {
          sumId.push(this.multipleSelection[i].id)
        }
        this.checkedid = sumId.join(',')
        this.deletefrom();
        this.deleteform = false;
    },
    //表格划入
    cellMouseEnter(row) {
      row.vshow = true
    },
    //表格滑出
    cellMouseLeave(row) {
      row.vshow = false;
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].optionshow = false
      }
    },
    //分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.getdata();

    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getdata();
    },
    //文件夹显示
    tabletext(val, text) {
      if (text == 'text') {
        this.tableheight = `${document.body.offsetHeight - 380}px`
        this.textshow = true;
        this.textvalue = val;
      } else {
        this.$router.push({
          path: '/marketForm/detailForm'
        })
      }
    },
    texthidden() {
      this.tableheight = `${document.body.offsetHeight - 340}px`
      this.textshow = false;
      this.textvalue = '';
    },
    //克隆编辑时间格式
    cloneformevent(key) {
      this.Cloneform = true;
      let newdate = new Date();
      let Y = newdate.getFullYear();//年
      var M = newdate.getMonth();//月
      let D = newdate.getDate();//日
      let H = newdate.getHours();//时
      let m = newdate.getMinutes(); //分
      let S = newdate.getSeconds();//秒
      let monlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      let Mon = monlist[M];
      let Mdate;
      if (H >= 12) {
        Mdate = Mon + '  ' + D + " , " + Y + "  " + (H - 12) + ":" + m + ":" + S + '  PM '
      } else {
        // eslint-disable-next-line no-unused-vars
        Mdate = Mon + '  ' + D + " , " + Y + "  " + H + ":" + m + ":" + S + ' AM '
      }
      this.Cloneformvalue = key.subject + "( " + this.$i18n.t('label.clone') + " )";
      // this.Cloneformvalue = key.subject + "(" + Mdate + ")  ( clone )";
      this.Cloneformid = key.id;
    },
    //获取隐藏字段
    getMember(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFieldDefault(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //获取注册页面
    getSource(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFieldShow(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //获取详细
    getDite(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFormdetail(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //克隆
    formclone() {
      let params = {
        id: this.Cloneformid,
      }
      let cloneda = {};
      Promise.all([this.getMember(params), this.getSource(params), this.getDite(params)]).then((res) => {
        res[1].data.selectedFields.map((item)=>{
          item.required=item.required?item.required:"0"
        })
        cloneda.leadDefaultValues = JSON.stringify(res[0].data.leaddvalue);
        cloneda.fields = JSON.stringify(res[1].data.selectedFields);
        cloneda.channel = this.Cloneformvalue;
        cloneda.subject = this.Cloneformvalue;
        cloneda.remark = res[2].data.remark;
        cloneda.logo = res[2].data.logo;
        cloneda.bgimage = res[2].data.bgimage;
        cloneda.modelType = res[2].data.modeltype;
        cloneda.rtnType = res[2].data.rtntype;
        cloneda.rtnUrl = res[2].data.rtnurl;
        cloneda.campaignId = res[2].data.campaignid;
        cloneda.userEmailIds = res[2].data.userEmailIds;
        cloneda.emails = JSON.stringify(res[2].data.emailList);
        if (res[2].data.longitude) {
          cloneda.longitude = res[2].data.longitude;
        } else {
          cloneda.longitude = '';
        }
        if (res[2].data.latitude) {
          cloneda.latitude = res[2].data.latitude;
        } else {
          cloneda.latitude = '';
        }
        if (res[2].data.address) {
          cloneda.address = res[2].data.address;
        } else {
          cloneda.address = '';
        }
        cloneda.operation = 'COPY';
        cloneda.objectid= this.$store.state.creatform.objectid
        cloneda.ownerobjectid= this.$store.state.creatform.ownerobjectid
        this.clonesave(cloneda);
      }).catch(() => {
        return false
      })
    },
    clonesave(cloneda) {
      this.Cloneform = false;
      marketChannel.saveForm(cloneda).then((res) => {
        if (res.returnCode == "1") {

          this.$message.success(this.$i18n.t("label.copy.success"))
          this.getdata();
        } else {
          this.$message.error(this.$i18n.t("label.copy_error"));
        }
      })
    },
    appearsonevent(key) {
      if (key.text !== 'text' && key.appears !== 'Non-Lighting Forms') {
        this.appearson = true;
        this.appearsonselect = key.appears;
      }

    },
    //获取form列表数据
    getdata() {
      let obj = {
        searchKeyWord: this.searchValue,
        endTime: this.enddate,
        startTime: this.startdate,
        pageNum: this.currentPage,
        pageSize: this.pagesize,
        formType: '',
        sortField: this.sortField,
        sortDir: this.sortDir,
      };

      marketChannel.getFormList(obj).then((res) => {
        this.currentPagetotle = res.data.totalCount;
        let formlist = res.data.formlist;
        for (var i = 0; i < formlist.length; i++) {
          formlist[i].vshow = false;
          formlist[i].optionshow = false;
          formlist[i].selectvalue = {
            selectvalue1: [this.$i18n.t("label.emailtocloudcc.button.view"), this.$i18n.t("label.report.details"), this.$i18n.t("label.file.share"), this.$i18n.t("label.view_submissions"), this.$i18n.t("label.export_submission"),],
            // this.$i18n.t("label.create_contacts"),
            selectvalue2: [this.$i18n.t("pagecreator.page.button.delete")],
          };
        }

        this.tableData = [...formlist];
      })

    },
    //删除单条数据
    deletefrom() {
      let obj = {
        id: this.checkedid,
      };
      marketChannel.deleteForm(obj).then((res) => {
        if (res.returnCode == '1') {
          this.$message({
            message: this.$i18n.t('label.tabpage.delsuccessz'),
            type: 'success'
          });
          this.deleteshow = false;
          this.getdata();
        } else {
          this.$message.error(this.$i18n.t('label.ems.delete.false'));
          this.deleteshow = false;
        }
      })
    },
    //获取时间范围
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      if (time == this.$i18n.t('component.timeline.label.today')) {
        this.startdate = nowDate;
        this.enddate = nowDate;
      }
      let lastDate = '';
      if (time == this.$i18n.t("label.brief.thisweek")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 7);
      }
      if (time == this.$i18n.t("label.notification.last30days")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
      }
      if (time == this.$i18n.t("this.year")) {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
      }
      if (time == this.$i18n.t("label.all_time")) {
        this.startdate = '';
        this.enddate = '';
      }
      if (lastDate) {
        var lastY = lastDate.getFullYear();
        var lastM = lastDate.getMonth() + 1;
        var lastD = lastDate.getDate();
        var lastdate = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
    },
    //获取form的分享信息
    getshare(even) {
      this.$refs.share.shareDialog = true
      this.$refs.share.checkedid = even.id;
      this.$refs.share.init()
    },
    //导出数据
    exporedata() {
      if (this.exportitle == this.$i18n.t("label.export_submission")) {
        let obj = {
          id: this.checkedid,
          exportType: this.ExportDatavalue,
        }
       
        axios.post(`form/exportFormInfo`, obj, {
          // responseType: "arraybuffer",
          responseType: 'blob',
        }).then((res) => {

          let blob = new Blob([res],); //type这里表示xlsx类型
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = 'exportform.' + this.ExportDatavalue; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象 
          this.ExportData = false;
        })
      }
      else {
        let obj = {
          exportType: this.ExportDatavalue,
          startTime: this.startdate,
          endTime: this.enddate,
          searchKeyWord: '',
          sortField: this.sortField,
          sortDir: this.sortDir,
        }
        axios.post(`/form/exportFormList`, obj, {
          // responseType: "arraybuffer",
          responseType: 'blob',
        }).then((res) => {

          let blob = new Blob([res],); //type这里表示xlsx类型
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = 'exportform.' + this.ExportDatavalue; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象 
          this.ExportData = false;
        })
      }
    },
    tabheight() {
      this.tableheight = (document.body.offsetHeight - 310) + 'px'
    }
  },
  created() {
    // if (this.$store.state.creatform.Daterange) {
    //   this.Daterange = this.$store.state.creatform.Daterange
    // }
    window.addEventListener('resize', this.tabheight);
    this.getformdates(this.Daterange);
    this.getdata();
  },
  beforeDestroy() {
    // 移除绑定的listenResizeFn事件监听
    window.removeEventListener("resize", this.tabheight);
  }

}
</script>
<style lang='scss' scoped>
::v-deep :focus {
  outline: 0;
}
::v-deep .el-table__row:hover {
  background: #f5f8fa;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  margin-top: 5px;
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}
input {
  outline: none;
}
button {
  outline: none;
}
.line-two-btn {
  .btn {
    padding: 0;
    height: 29px;
    padding: 0 4px;
    border: 1px solid #dedcda;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .group-btn {
    // margin-top: -20px;
    padding: 0 4px;
    width: 30px;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 4px;
  }
}
.el-button--primary.is-plain {
  color: #2D6CFC;
  background: #fff;
  border-color: #ccc;
}
.el-button--primary.is-plain:hover {
  color: #ffffff;
  background: #2D6CFC;
}
.ivu-table-wrapper {
  overflow: visible;
}
.icon {
  display: inline-block;
  margin: 0 10px;
}
.marketform {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .Form {
    width: 99%;
    height: 98%;
    margin-left: 10px;
    margin-top: 10px;
    background: #fff;
  }
}
.formhead {
  height: 50px;
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  overflow: hidden;
}
.formheadleft {
  height: 50px;
  .headleftfirstp {
    line-height: 50px;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #080707;
    padding-left: 10px;
    font-weight: 900;
  }
  .headleftfirstspan {
    color: #888888;
    font-size: 14px;
  }
  i {
    font-size: 12px;
  }
  .headleftsecondspan {
    font-family: MicrosoftYaHei-Bold;
    color: #2D6CFC;
    font-size: 12px;
    font-weight: 900;
  }
}
.formheadright {
  ::v-deep .el-button {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    border-radius: 3px;
  }
  .autofocus {
    color: #ffffff;
    background: #2D6CFC;
  }
}
.formnav {
  width: 99%;
  height: 40px;
  border-bottom: 1px solid #dedcda;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  div {
    height: 40px;
    p {
      float: left;
      width: 106px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    .pselect {
      width: 106px;
      height: 38px;
      font-weight: bolder;
      border-bottom: 3px solid #2D6CFC;
    }
  }
  span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #2D6CFC;
    letter-spacing: 0;
    cursor: pointer;
    i {
      color: #888888;
      font-size: 14px;
    }
  }
}
.marketbody {
  width: 99%;
  margin-left: 10px;
  .bodynav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    .bodynavleft {
      height: 100%;
      display: flex;
      align-items: center;
      ::v-deep .el-input {
        width: 200px;
      }
      ::v-deep .el-input__inner {
        border: 1px solid #dedcda;
        height: 30px;
        float: left;
        width: 200px;
      }

      ::v-deep .el-icon-search:before {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .Daterange {
        float: left;
        margin-left: 16px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        width: 250px;
        .el-dropdown-link {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #2D6CFC;
          letter-spacing: 0;
        }
      }
    }
    .bodynavright {
      display: flex;
      justify-content: space-around;
    }
    .datepicker {
      margin-left: 10px;
      ::v-deep {
        .el-range-editor.el-input__inner {
          padding: 0;
          cursor: pointer;
          width: 220px;
        }
        .el-input__inner {
          border: 0;
          height: 20px;
          line-height: 20px;
        }
        .el-range-input {
          cursor: pointer;
          color: #2D6CFC;
        }
        .el-date-editor .el-range__icon {
          line-height: 20px;
          margin: 0;
          width: 20px;
        }
        .el-date-editor .el-range-separator {
          line-height: 20px;
          padding: 0;
        }
        .el-date-editor .el-range-input {
          width: 90px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 22px;
          width: 20px;
        }
      }
    }
  }
}
::v-deep .el-table {
  overflow: auto;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  overflow-x: hidden;
}
.table {
  margin-bottom: 60px;
 ::v-deep td{
   .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color:#2D6CFC;
      border-color:#2D6CFC;
    }
 }
  ::v-deep th{
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color:#2D6CFC;
      border-color:#2D6CFC;
    }
  }
}
.tablename {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 64px;
  .tablenamefirst {
    color: #2D6CFC;
    font-size: 14px;
    cursor: pointer;
    .secol {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #2D6CFC;
      display: inline-block;
    }
    .tablenamebloder {
      font-weight: bolder;
      font-family: MicrosoftYaHei-Bold;
    }
    .tablesecol {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #888888;
    }
  }
  .tablenamesecond {
    button {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      letter-spacing: 0;
      background-color: #eaf0f6;
      color: #506e91;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      margin-right: 10px;

      padding: 5px 12px;
      cursor: pointer;
    }
    button:hover {
      background: none;
      color: none;
    }
    .actionselect {
      background-color: #eaf0f6;
      color: #506e91;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      letter-spacing: 0;
      padding: 5px 12px;
      .borderli {
        width: 100%;
        height: 1px;
        background: #dedcda;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      .actionoption {
        position: absolute;
        right: -90px;
        top: 50px;
        background: #fff;
        z-index: 999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #2D6CFC;
        }
      }
      .actiontop {
        position: absolute;
        right: -50px;
        bottom: 50px;
        background: #fff;
        z-index: 9999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #2D6CFC;
        }
      }
    }
  }
}
.formplaces {
  color: #2D6CFC;
  font-weight: bold;
}
.formtable {
  margin-top: 5px;
}
.block {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 50px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ffff;

  // background: #fff;
  // width: 100%;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // padding-left: 20px;
  // z-index: 9;
}
.texttf {
  width: 100%;
  height: 30px;
  font-weight: bolder;
  font-size: 14px;
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: #ccc;
    margin-left: 10px;
  }
  i {
    padding-right: 10px;
    color: #2D6CFC;
  }
}
::v-deep .dialog {
  .dialogdiv {
    width: 100%;
    .el-select {
      width: 100%;
    }
    input {
      width: 100%;
      border: 1px solid #dedcda;
      border-radius: 3px;
      height: 40px;
    }
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
    div {
      margin-bottom: 20px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
      b {
        color: #2D6CFC;
      }
    }
  }
  .dialogappearson {
    width: 100%;
    h5 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #fafaf9;
      border: 1px solid #dedcda;
      border-radius: 3px 3px 0 0;
      padding-left: 10px;
    }
    h6 {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dedcda;
      height: 60px;
      border: 1px solid #dedcda;
      border-top: none;
      padding-left: 10px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
      }
    }
  }
}
.sharecontent {
  width: 94.6%;
  margin: 2.7%;
  .sharehead {
    width: 100%;
    height: 111px;
    background: #fef8f0;
    border: 1px solid #ffdfbb;
    border-radius: 1px;
    font-family: MicrosoftYaHei-Bold;
    padding-left: 20px;
    h1 {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: bolder;
      line-height: 35px;
      span {
        font-size: 14px;
        color: #151515;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: normal;
      }
    }
  }
  .embcode {
    width: 100%;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 15px;
      span {
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
        line-height: 19px;
        .icon {
          display: inline-block;
          width: 12px;
          height: 14px;
        }
      }
    }
    .sourcecode {
      width: 100%;
      height: 163px;
      overflow: auto;
      overflow-x: hidden;
    }
    .embcontent {
      width: 100%;
      min-height: 163px;
      border: 1px solid #dedcda;
      background: rgb(157, 219, 209);
    }
  }
  .Slink {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    .slinkcontent {
      width: 100%;
      margin-top: 14px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input {
        width: 85%;
        height: 40px;
        outline: none;
        border: 1px solid #dedcda;
        border-radius: 1px;
        border-right: none;
        padding-left: 13px;
      }
      button {
        width: 15%;
        height: 46px;
        border-left: none;
        outline: none;
        background: #2D6CFC;
        border-radius: 1px;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        cursor: pointer;
        border: 1px solid #dedcda;
      }
    }
    span {
      display: inline-block;
      margin-top: 19px;
      color: #2D6CFC;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: bolder;
      .icon {
        display: inline-block;
      }
    }
  }
  .QRcode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 154px;
      height: 154px;
      margin-top: 54px;
      margin-bottom: 54px;
    }
  }
}
.deletecontent {
  width: 94.6%;
  margin-left: 2.7%;
  p {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
  }
  span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #2D6CFC;
    letter-spacing: 0;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
.appearstyle {
  cursor: pointer;
}
.batchremove {
  padding-left: 5px;
  img {
    margin-left: 20px;
    margin-right: 8px;
  }
  span {
    color: #2D6CFC;
    cursor: pointer;
  }
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}
/* 筛选器位置 */
.screen {
  // height: 88%;
  height: calc(100% - 116px);
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}
#spanlength {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#spanlong {
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>